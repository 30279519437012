import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/client/clients', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/client/clients/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/client/clients', { client: clientData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
